
import { defineComponent, ref } from "vue";
import Score from "@/components/Score.vue";
//import VueGauge from "vue-gauge";

export default defineComponent({
  name: "score-chart",
 // components: { VueGauge },
  props: {
    num: {
      required: true,
    },
    with: {
      required: true,
    },
    percent: {
      required: true,
      type: Number,
    },
    description: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
    max: {
      required: true,
    },
    score: {
      required: true,
    },
  },
  setup: function (props) {
    const series = ref([props.percent]);
    const chartVal = ref({
      chartWidth: props.with,
      needleValue: props.percent,
      arcDelimiters: [20, 40, 60, 80],
      arcLabels: [props.percent + "%"],
      centralLabel: props.score + "/" + props.max,
      arcColors: ["#ff0000", "#ff4800", "#ffc600", "#00d800", "#00cc00"],
      hasNeedle: true,
    });
    /*        const chartOptions = ref({
          chart: {
            height: 350,
            type: "radialBar",
            offsetY: -10,
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: "16px",
                  color: undefined,
                  offsetY: 120,
                },
                value: {
                  offsetY: 76,
                  fontSize: "22px",
                  color: "#92db73",
                  formatter: function (val) {
                    return `${props.max}`;
                  },
                },
              },
            },
          },
          fill: {
            colors: [
              function ({ value, seriesIndex, w }) {
                if (value < 40) {
                  return "#ff6972";
                } else if (value >= 40 && value < 65) {
                  return "#fdd974";
                } else {
                  return "#92db73";
                }
              },
            ],
          },
          opacity: 0.9,
          type: "solid",
          labels: [props.score],
          colors: [
            props.percent < 40
              ? "#ff6972"
              : props.percent >= 40 && props.percent < 65
              ? "#fdd974"
              : "#92db73",
          ],
        });*/
    return {
      series,
      //   chartOptions,
      chartVal,
    };
  },
});


import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ScoreChart from "@/components/ScoreChart.vue";

export default defineComponent({
  name: "survey",
  components: { ScoreChart },

  setup: function () {
    const router = useRouter();
    const route = useRoute();
    let surveyID = ref();
    let data = ref();
    onMounted(() => {
      getResults();
    });

    async function getResults() {
      surveyID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "/" });
      apiService
        .get(`score/survey/${surveyID.value}`)
        .then(
          await function (resp) {
            if (resp.data.data) {
              data.value = resp.data.data;
            }
          }
        )
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    return {
      data,
    };
  },
});

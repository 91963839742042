
import { computed, defineComponent, onMounted } from "vue";
import Score from "@/components/Score.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "users-details-result",
  components: {
    Score
  },
  setup() {
    const store = useStore();

    const userID = computed(() => {
      return store.getters.currentUser.id;
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Assessment", []);
    });
  }
});
